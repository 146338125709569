/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import {
  Button,
  List,
  Modal,
  Popconfirm,
  Popover,
  Switch,
  Table,
  Tag,
  message,
  Form,
  Input,
} from 'antd';
import {
  CheckOutlined,
  DeleteFilled,
  DownloadOutlined,
  ExclamationCircleFilled,
  YoutubeFilled,
} from '@ant-design/icons';
import qs from 'querystring';
import dayjs from 'dayjs';
import { useIntl } from 'react-intl';
import { SorterResult, TablePaginationConfig } from 'antd/es/table/interface';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import irUrl from '../../utils/irUrl';
import UploaderProps, { CommentProps } from '../../props/uploader';
import Comment from './comment';
import { userState } from '../../store/atom';
import { useRecoilValue } from 'recoil';
import { postRequest } from '../../utils/ajax';

const { Item } = Form;
const { Column } = Table;

interface MatchProps {
  callback: () => void;
  submitRefresh: boolean;
  tagsSearch: string[];
  keysSearch: string[];
}

const noSubtitleStyle: React.CSSProperties = { fontStyle: 'italic' };

const difficultyStyle: React.CSSProperties[] = [
  { color: '#00a86b', fontWeight: 'bold' },
  { color: '#0080ff', fontWeight: 'bold' },
  { color: '#ffd300', fontWeight: 'bold' },
  { color: '#b80f0a', fontWeight: 'bold' },
  { color: '#be29ec', fontWeight: 'bold' },
];

const judgeStyle: React.CSSProperties[] = [
  { color: '#b80f0a' },
  { color: '#ffd300' },
  { color: '#0080ff' },
  { color: '#00a86b' },
];

const TableComponent: React.FC<MatchProps> = ({ callback, submitRefresh, tagsSearch, keysSearch }) => {
  const [form] = Form.useForm();

  const location = useLocation();
  const query = qs.parse(location.search.substring(1));
  const [dataSource, setDataSource] = useState<UploaderProps[]>([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [deleteDisable, setDeleteDisable] = useState(false);
  const [commentNo, setCommentNo] = useState(0);
  const [listComments, setListComments] = useState<CommentProps[]>([]);
  const [tableRefresh, setTableRefresh] = useState(true);
  const [removeRefresh, setRemoveRefresh] = useState(false);
  const [toggleSwitch, setToggleSwitch] = useState(false);
  const user = useRecoilValue(userState);
  const history = useHistory();
  const { formatMessage } = useIntl();

  const onChange = (
    tablePagination: TablePaginationConfig,
    filters: Record<string, (string | number | boolean)[] | null>,
    sorter: SorterResult<UploaderProps> | SorterResult<UploaderProps>[],
  ) => {
    const setQuery = (field: any, value: any) => {
      if (field && value) {
        queryObject[field] = value;
      }
    };

    const queryObject: any = {};
    const { current: page } = tablePagination;

    if (page) {
      setQuery('page', page);
    }

    if (Array.isArray(sorter)) {
      const { field, order } = sorter[0];
      setQuery(field, order === 'descend' ? 'desc' : 'asc');
    } else {
      const { field, order } = sorter;
      setQuery(field, order === 'descend' ? 'desc' : 'asc');
    }

    if (filters.level && filters.level.length > 0) {
      setQuery('levelfilter', filters.level.join(','));
    }

    if (tagsSearch.length > 0) {
      queryObject.tags = tagsSearch.join(',');
    }
    if (keysSearch.length > 0) {
      queryObject.keys = keysSearch.join(',');
    }
    if (query.search) {
      queryObject.search = query.search;
    }

    history.push(`?${qs.stringify(queryObject)}`);
    setRemoveRefresh(true);
  };

  useEffect(() => {
    const onTableChange = async () => {
      const result = await postRequest('/api/uploader', { ...query });
      if (!result.success) {
        if (result.error) message.error(formatMessage({ id: `error.${result.error}` }));
        else message.error('Unknown Error.');
      } else {
        setTotal(result.total);
        setDataSource([...result.source]);
      }
      setLoading(false);
    };
    if (tableRefresh || submitRefresh || removeRefresh) {
      setTableRefresh(false);
      setRemoveRefresh(false);
      callback();
      setTotal(0);
      setDataSource([]);
      setLoading(true);
      onTableChange();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, submitRefresh, removeRefresh]);

  const onSwitch = (checked: boolean) => {
    setToggleSwitch(checked);
  };

  const onShowComment = (comments: CommentProps[], entryNo: number) => {
    setCommentNo(entryNo);
    setListComments(comments);
    setVisible(!visible);
  };

  const onRemove = async (entryNo: number) => {
    const result = await postRequest(`/api/uploader/delete/${entryNo}`);
    if (!result.success) {
      if (result.error) {
        message.error(formatMessage({ id: `error.${result.error}` }));
      } else message.error('Unknown Error.');
    } else {
      setRemoveRefresh(true);
      message.success('OK');
    }
  };

  const onDeleteFinish = async (values: any) => {
    if (values.entryNo && values.password) {
      setDeleteDisable(true);
      const result = await postRequest(`/api/uploader/delete/${values.entryNo}`, {
        password: values.password,
      });
      if (!result.success) {
        if (result.error) message.error(formatMessage({ id: `error.${result.error}` }));
        else message.error('Unknown Error.');
        setDeleteDisable(false);
      } else {
        setRemoveRefresh(true);
        message.success('OK');
      }
    } else {
      message.error('Unknown Error.');
    }
  };

  const commentCallback = (comment: CommentProps) => {
    setListComments([...listComments, comment]);
    setTableRefresh(true);
  };

  return (
    <div>
      <div className="normal-container" style={{ textAlign: 'right', paddingRight: '12px' }}>
        <p>
          {formatMessage({ id: 'uploaderPage.moreDetail' })}
          <Switch onChange={onSwitch} size="small" />
        </p>
      </div>
      <Table<UploaderProps>
        bordered
        scroll={{ x: 720 }}
        dataSource={dataSource}
        loading={loading}
        pagination={{
          defaultCurrent: query.page ? Number(query.page) : 1,
          pageSize: 20,
          showSizeChanger: false,
          total,
        }}
        rowKey="_id"
        size="small"
        onChange={onChange}
        onHeaderRow={() => {
          return {
            style: { backgroundColor: 'white' },
          };
        }}
      >
        <Column<UploaderProps>
          ellipsis
          key="title"
          title={formatMessage({ id: 'uploaderPage.thTable.0' })}
          dataIndex="title"
          render={(plaintext, record) => (
            <Popover content={record.title}>
              <span>{record.title}</span>
            </Popover>
          )}
          width={300}
        />
        <Column<UploaderProps>
          key="level"
          title="LV"
          dataIndex="level"
          render={(plaintext, record) => record.charts.map((i) => `${i.mark}${i.level}`).join(',')}
          width={100}
        />
        <Column<UploaderProps>
          ellipsis
          key="charts"
          title={formatMessage({ id: 'uploaderPage.thTable.1' })}
          dataIndex="charts"
          align="center"
          render={(plaintext, record) => (
            <Popover
              content={
                <div>
                  {record.charts.map((i) => (
                    <div>
                      {i.chartname ? (
                        <span
                          style={
                            i.difficulty <= 5 && i.difficulty >= 1
                              ? difficultyStyle[i.difficulty - 1]
                              : undefined
                          }
                        >
                          {i.mark}
                          {i.level} {i.chartname}
                        </span>
                      ) : (
                        <span>
                          {i.mark}
                          {`${i.level} `}
                          <span style={noSubtitleStyle}>
                            {formatMessage({
                              id: 'uploaderPage.noSubtitle',
                            })}
                          </span>
                        </span>
                      )}
                      &nbsp;{i.notes} NOTES,&nbsp;
                      <span style={judgeStyle[i.judge]}>{i.judgeText}</span>
                      {formatMessage({ id: 'uploaderPage.judge' })}, BPM:
                      <span>{i.maxbpm === i.minbpm ? i.maxbpm : `${i.minbpm}~${i.maxbpm}`}</span>,
                      TOTAL:
                      <span style={{ marginRight: '10px' }}>{i.total}</span>
                      <a href={irUrl(i.md5, 'lr2')} target="_blank" style={{ marginRight: '10px' }}>
                        LR2IR
                      </a>
                      <a
                        href={irUrl(i.sha256, 'beatoraja')}
                        target="_blank"
                        style={{ marginRight: '10px' }}
                      >
                        MinIR
                      </a>
                      <a href={`http://www.ribbit.xyz/bms/score/view?md5=${i.md5}`} target="_blank">
                        View Chart
                      </a>
                    </div>
                  ))}
                </div>
              }
            >
              <ExclamationCircleFilled style={{ color: '#1890ff', fontSize: '1.8em' }} />
            </Popover>
          )}
          width={60}
        />
        <Column<UploaderProps>
          key="action"
          title="Action"
          dataIndex="action"
          render={(plaintext, record) => (
            <span>
              <a href={`/upload/${record.entryNo}`}>
                <DownloadOutlined style={{ color: '#1890ff', fontSize: '1.8em' }} />
              </a>
              &nbsp;
              {record.youtube ? (
                <a href={record.youtube} target="_blank">
                  <YoutubeFilled style={{ color: '#ff2828', fontSize: '1.8em' }} />
                </a>
              ) : null}
              {user.nickname === record.proposer?.nickname ? (
                <Popconfirm
                  title="Are you sure?"
                  onConfirm={() => onRemove(record.entryNo)}
                  okText="Yes"
                  cancelText="No"
                >
                  <DeleteFilled style={{ fontSize: '1.8em' }} />
                </Popconfirm>
              ) : record.anonymousName ? (
                <Popover
                  content={
                    <span>
                      <Form
                        form={form}
                        layout="inline"
                        onFinish={onDeleteFinish}
                        initialValues={{ entryNo: record.entryNo }}
                      >
                        <Item style={{ margin: 0 }} name="entryNo">
                          <Input type="hidden" disabled defaultValue={record.entryNo} />
                        </Item>
                        <Item style={{ margin: 0 }} name="password">
                          <Input
                            type="password"
                            placeholder={formatMessage({
                              id: `uploaderPage.deletePasswordLabel`,
                            })}
                            size="small"
                            style={{ width: '100px' }}
                          />
                        </Item>
                        <Item style={{ margin: 0 }}>
                          <Button
                            htmlType="submit"
                            disabled={deleteDisable}
                            type="primary"
                            size="small"
                          >
                            <CheckOutlined />
                          </Button>
                        </Item>
                      </Form>
                    </span>
                  }
                  style={{ whiteSpace: 'nowrap' }}
                  trigger="click"
                >
                  <DeleteFilled style={{ fontSize: '1.8em' }} />
                </Popover>
              ) : null}
            </span>
          )}
          width={80}
        />
        <Column<UploaderProps>
          key="downloadCount"
          title="DL"
          dataIndex="downloadCount"
          width={40}
        />
        {toggleSwitch ? (
          <Column<UploaderProps>
            ellipsis
            key="artist"
            title={formatMessage({ id: 'uploaderPage.thTable.2' })}
            dataIndex="artist"
            render={(plaintext, record) => (
              <span>
                {record.songUrl ? <a href={record.songUrl}>{record.artist}</a> : record.artist}
              </span>
            )}
            width={180}
          />
        ) : null}
        <Column<UploaderProps>
          key="proposer"
          title={formatMessage({ id: 'uploaderPage.thTable.3' })}
          dataIndex="proposer"
          render={(plaintext, record) =>
            record.proposer ? (
              <Link to={`/user/sl/${record.proposer.userlr2id || record.proposer.minirid}`}>
                {record.proposer.nickname}
              </Link>
            ) : (
              <span>{record.anonymousName}</span>
            )
          }
          width={100}
        />
        <Column<UploaderProps>
          key="keys"
          title={formatMessage({ id: 'uploaderPage.thTable.5' })}
          dataIndex="keys"
          render={(plaintext, record) => (
            <span>
              {record.keys.map((i) => (
                <Tag>{i}</Tag>
              ))}
            </span>
          )}
          width={180}
        />
        <Column<UploaderProps>
          key="tags"
          title={formatMessage({ id: 'uploaderPage.thTable.6' })}
          dataIndex="tags"
          render={(plaintext, record) => (
            <span>
              {record.tags.map((i) => (
                <Tag>{formatMessage({ id: `uploaderPage.tags.${i}` })}</Tag>
              ))}
            </span>
          )}
          width={180}
        />
        <Column<UploaderProps>
          key="comments"
          title={formatMessage({ id: 'uploaderPage.thTable.4' })}
          dataIndex="comments"
          render={(plaintext, record) => (
            <Button
              type="primary"
              size="small"
              onClick={() => onShowComment(record.comments, record.entryNo)}
            >
              {formatMessage({ id: 'uploaderPage.seeComment' })}({record.comments.length})
            </Button>
          )}
          width={150}
        />
        {toggleSwitch ? (
          <Column<UploaderProps>
            ellipsis
            key="event"
            title={formatMessage({ id: 'uploaderPage.thTable.7' })}
            dataIndex="Event"
            render={(plaintext, record) => (
              <Popover content={record.event}>
                <span>{record.event}</span>
              </Popover>
            )}
            width={180}
          />
        ) : null}
        <Column<UploaderProps>
          ellipsis
          key="comment"
          title={formatMessage({ id: 'uploaderPage.thTable.8' })}
          dataIndex="comment"
          render={(plaintext, record) => (
            <Popover content={record.comment}>
              <span>{record.comment}</span>
            </Popover>
          )}
          width={180}
        />
        <Column<UploaderProps>
          key="publishedDate"
          title={formatMessage({ id: 'uploaderPage.thTable.9' })}
          dataIndex="publishedDate"
          render={(plaintext, record) => {
            return <span>{dayjs(record.publishedTime).format('YYYY/MM/DD HH:mm')}</span>;
          }}
          sorter={(a, b) => dayjs(a.publishedTime).unix() - dayjs(b.publishedTime).unix()}
          width={150}
        />
      </Table>
      <Modal
        title={formatMessage({ id: 'uploaderPage.listOfComments' })}
        visible={visible}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
        onCancel={() => {
          setCommentNo(0);
          setListComments([]);
          setVisible(!visible);
        }}
      >
        {user.userid ? (
          <Comment submission={commentNo} callback={commentCallback} />
        ) : (
          <div className="normal-container text-center">
            {formatMessage({ id: 'uploaderPage.requireLogin' })}
          </div>
        )}
        <List
          bordered
          itemLayout="horizontal"
          dataSource={listComments}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                title={(() => (
                  <span>
                    <Link to={`/user/sl/${item.voter?.userlr2id || item.voter?.minirid}`}>
                      {item.voter ? item.voter.nickname : '-Deleted Account-'}
                    </Link>
                  </span>
                ))()}
                description={item.voterComment}
              />
            </List.Item>
          )}
        />
      </Modal>
    </div>
  );
};

export default TableComponent;
