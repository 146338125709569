import React from 'react';
import { useIntl } from 'react-intl';

const ChartAd: React.FC = () => {
  const { formatMessage } = useIntl();
  const lang = localStorage.getItem('lang');

  return (
    <>
      <div className="p-32-64">
        <div className="framed text-center">
          <h1>Sabun-Shuffle Summer Season</h1>
          <a href="https://darksabun.github.io/event/ssss/" target="_blank" rel="noreferrer">
            {window.innerWidth >= 1200 ? (
              <img src={`banner9.png`} alt="banner_support" />
            ) : (
              <img src={`banner9_sm.png`} alt="banner_support_sm" />
            )}
          </a>
        </div>
      </div>
      <div className="p-32-64">
        <div className="framed text-center">
          <h1>{formatMessage({ id: 'homePage.bannerSupport' })}</h1>
          <a href="https://discord.com/invite/44XgsF4" target="_blank" rel="noreferrer">
            {window.innerWidth >= 1200 ? (
              <img src={`banner_support_${lang}.png`} alt="banner_support" />
            ) : (
              <img src={`banner_support_${lang}_sm.png`} alt="banner_support_sm" />
            )}
          </a>
        </div>
      </div>
    </>
  );
};

export default ChartAd;
